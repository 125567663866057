













var loadAntiPhisingscript = function(){
  let wHostName = window.location.host;
  window.isAntiPhishingReq = true;
  console.log('loading script');
  if(window.isAntiPhishingReq){

    let secureurlCookie = "",
    scriptTag = document.createElement("script"),
    scriptTagHelper = document.createElement("script"),
    antiPhisingUrl = "",
    antiPhisingHelper = "";
   document.cookie.split('; ').forEach(function(row) {
       if (row.startsWith('DomainUrl=')) {
        secureurlCookie = row;
       }
   })

    if(secureurlCookie){
      antiPhisingUrl = secureurlCookie.split("=")[1]+"/client/helper/spa-assets/components/utilities/client-helper/GIS/ap/1.0.0/js/ap.js";
      antiPhisingHelper = secureurlCookie.split("=")[1]+"/client/helper/spa-assets/components/utilities/client-helper/GIS/helper/1.0.0/js/helper.js";
    }
    else{
      antiPhisingUrl = "https://secure.bankofamerica.com/client/helper/spa-assets/components/utilities/client-helper/GIS/ap/1.0.0/js/ap.js"
      antiPhisingHelper = "https://secure.bankofamerica.com/client/helper/spa-assets/components/utilities/client-helper/GIS/helper/1.0.0/js/helper.js"














    }

        scriptTag.setAttribute("src", antiPhisingUrl);
    document.getElementsByTagName("head")[0].appendChild(scriptTag);

        scriptTagHelper.setAttribute("src", antiPhisingHelper);
    document.getElementsByTagName("body")[0].appendChild(scriptTagHelper);

        scriptTag.onload = function(){
    console.log('anti phising js loaded');
    }
    document.cookie = 'DomainUrl=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }

}
loadAntiPhisingscript();



